<template lang="pug">
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 proton.ai
  div
    h3 You've successfully logged out of Proton.
    p To log back in, please visit your company-specific Proton URL (yourcompany.proton.ai)
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'Logout',

  created () {
    Cookies.remove('user_id', { path: '', domain: '.proton.ai'})
    Cookies.remove('user_id_override', { path: '', domain: '.proton.ai'})
    Cookies.remove('company', { path: '', domain: '.proton.ai'})
    Cookies.remove('api_key', { path: '', domain: '.proton.ai'})
  },
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>
